import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 238.000000 229.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,229.000000) scale(0.100000,-0.100000)">


<path d="M1073 1990 c-361 -45 -664 -334 -729 -695 -61 -343 88 -679 386 -870
124 -79 309 -135 451 -135 151 0 333 55 461 139 365 242 496 701 308 1086
-160 327 -515 520 -877 475z m237 -106 c47 -26 115 -89 152 -142 15 -22 17
-23 52 -8 l37 15 -25 37 c-24 36 -26 54 -5 54 22 0 140 -83 137 -97 -2 -8 -40
-27 -85 -42 -106 -38 -262 -123 -392 -216 -66 -47 -115 -75 -131 -75 -37 0
-66 -24 -74 -62 -4 -23 -27 -53 -74 -98 -37 -36 -91 -91 -121 -122 l-54 -57
24 -20 c13 -11 29 -20 34 -21 6 0 61 52 123 115 65 66 121 115 132 115 36 0
69 23 80 55 19 58 264 223 439 296 73 30 173 51 199 42 20 -8 14 -64 -14 -123
-20 -43 -24 -63 -18 -95 10 -62 19 -73 37 -44 27 42 57 103 57 115 0 6 7 17
15 24 13 11 20 2 49 -61 42 -92 64 -179 72 -290 8 -98 -2 -132 -29 -104 -10 9
-16 30 -15 50 1 38 -21 70 -56 81 -11 3 -41 25 -65 48 l-43 41 6 -75 c10 -109
22 -139 62 -156 19 -8 41 -28 50 -46 23 -43 30 -140 14 -179 -18 -43 -81 -86
-163 -110 -58 -18 -87 -21 -197 -17 -109 3 -132 7 -150 23 -16 15 -38 19 -93
21 -144 3 -409 131 -586 284 -137 118 -213 238 -215 340 -2 90 49 147 161 180
33 10 44 20 53 43 16 47 13 50 -29 43 -63 -10 -62 11 5 71 125 113 256 168
181 76 -40 -48 -93 -154 -122 -243 -16 -48 -40 -99 -56 -118 -32 -36 -35 -52
-17 -85 8 -14 11 -57 9 -118 -2 -79 0 -98 14 -109 15 -13 20 -11 40 12 17 20
25 45 30 100 5 49 14 81 27 98 16 20 19 34 13 77 -11 87 53 249 143 361 113
144 253 186 382 116z m-875 -662 c6 -4 24 -29 39 -56 41 -70 158 -190 256
-259 109 -78 284 -167 411 -207 54 -18 99 -36 99 -41 0 -17 44 -49 67 -49 13
0 34 7 46 14 18 12 40 13 120 6 86 -8 234 1 257 15 3 2 12 -1 20 -6 12 -7 5
-18 -35 -54 -94 -85 -215 -158 -215 -130 0 11 43 79 52 83 14 6 9 30 -8 36
-24 9 -64 7 -64 -4 0 -5 -27 -38 -59 -73 -105 -112 -204 -147 -317 -111 -114
35 -251 204 -308 379 -11 35 -25 56 -45 68 -15 9 -31 14 -35 10 -28 -29 44
-231 120 -334 19 -26 34 -49 34 -53 0 -17 -42 -2 -98 34 -62 40 -76 63 -47 74
13 5 13 10 -2 42 -17 35 -19 36 -60 30 -52 -7 -73 0 -73 25 0 31 38 129 66
172 l26 39 -23 24 c-12 13 -28 24 -35 24 -6 0 -29 -36 -50 -80 -22 -43 -45
-79 -52 -80 -18 0 -70 119 -92 214 -20 80 -28 256 -12 256 4 0 12 -4 17 -8z"/>
<path d="M895 1630 c-8 -16 -15 -33 -15 -38 0 -5 26 -13 58 -17 31 -4 93 -15
137 -25 l80 -19 39 26 c33 21 37 27 23 34 -21 13 -200 55 -259 63 -45 5 -49 4
-63 -24z"/>
<path d="M1585 1568 c-11 -6 -21 -12 -22 -13 -2 -1 7 -33 18 -71 55 -190 57
-475 3 -658 -20 -70 -19 -72 26 -68 37 4 41 7 51 44 39 147 53 400 30 554 -14
98 -53 218 -71 221 -8 2 -24 -2 -35 -9z"/>
<path d="M1408 1479 c-21 -11 -38 -23 -38 -28 0 -5 28 -23 63 -39 34 -17 77
-40 95 -52 35 -24 37 -20 21 44 -9 37 -18 48 -57 69 l-47 25 -37 -19z"/>
<path d="M1480 1164 c-89 -90 -221 -204 -316 -275 l-57 -42 39 -13 c22 -8 44
-14 50 -14 20 0 167 121 277 229 91 89 107 110 106 135 -1 73 -9 71 -99 -20z"/>
<path d="M896 715 c-16 -12 -18 -19 -8 -40 14 -31 30 -31 84 -4 l42 21 -35 19
c-41 22 -58 23 -83 4z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
